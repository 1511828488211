import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

import Page from '../components/Page';
import impressum from '../content/impressum';

const StyledContainer = styled(Container)`
  padding: 5rem 0;
  h1 {
    font-size: 3.75rem;
    margin-top: 5rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.25rem;
  }
`;

const Impressum = ({ show = false }) => (
  <Page show={show}>
    <StyledContainer>
      <Row className="justify-content-center">
        <Col lg={10}>
          <div dangerouslySetInnerHTML={{ __html: impressum }} />
        </Col>
      </Row>
    </StyledContainer>
  </Page>
);

Impressum.propTypes = {
  show: PropTypes.bool,
};

export default Impressum;
