import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import GalleryModal from '../components/GalleryModal';

import LazyImage from '../components/LazyImage';
import Page from '../components/Page';
import bilder from '../content/bilder';

const StyledGallery = styled.div`
  padding-bottom: 5rem;
  h1 {
    text-align: center;
    margin: 5rem 0 1.5rem 0;
    font-size: 2rem;
  }
  .image-container {
    margin: 0 auto;
    text-align: center;
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .btn {
      margin: 0;
      padding: 0;
      border: none;
    }
    .lazyload-wrapper {
      margin: 0.5rem;
    }
    .break {
      flex-basis: 100%;
    }
  }
  @media screen and (min-width: 992px) {
    h1 {
      font-size: 3.75rem;
    }
  }
`;

const Bilder = ({ show = false }) => {
  const [showGallery, setShowGallery] = React.useState(false);
  const [activeImage, setActiveImage] = React.useState(1);

  const prev = () => {
    if (activeImage > 1) {
      setActiveImage(activeImage - 1);
    } else {
      setActiveImage(bilder.length);
    }
  };
  const next = () => {
    if (activeImage >= bilder.length) {
      setActiveImage(1);
    } else {
      setActiveImage(activeImage + 1);
    }
  };

  return (
    <Page show={show}>
      <StyledGallery>
        <h1>Bilder</h1>
        <div className="image-container">
          {bilder.map((img) => (img.src
            ? (
              <React.Fragment key={img.key}>
                {img.intro ? (
                  <div
                    className="break"
                    dangerouslySetInnerHTML={{ __html: img.intro }}
                  />
                ) : null}
                <Button
                  variant="link"
                  onClick={() => {
                    setActiveImage(img.key);
                    setShowGallery(true);
                  }}
                >
                  <LazyImage src={`/img/bilder/thumb/${img.src}`} alt="" />
                </Button>
              </React.Fragment>
            ) : null))}
        </div>
      </StyledGallery>
      <GalleryModal
        show={showGallery}
        activeImage={activeImage}
        onHide={() => setShowGallery(false)}
        next={next}
        prev={prev}
      />
      <div className="mb-5 text-center">
        Weitere Bilder auf meiner Seite:
        {' '}
        <a href="https://artmaintaunus.wordpress.com/ingrid-jureit-2/" target="_blank" rel="noreferrer">https://artmaintaunus.wordpress.com/</a>
      </div>
    </Page>
  );
};

Bilder.propTypes = {
  show: PropTypes.bool,
};

export default Bilder;
