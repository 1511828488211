import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

import Page from '../components/Page';
import Timeline from '../components/Timeline';
import ausstellungen from '../content/ausstellungen';

const StyledContainer = styled(Container)`
  padding-bottom: 5rem;
  h1 {
    margin: 5rem 0 1.5rem 0;
    text-align: center;
    font-size: 2.5rem;
  }
  .intro {
    text-align: center;
    margin-bottom: 5rem;
  }
  @media screen and (min-width: 992px) {
    h1 {
      font-size: 3.75rem;
    }
  }
`;

const Ausstellungen = ({ show = false }) => (
  <Page show={show}>
    <StyledContainer>
      <Row>
        <Col>
          <h1>Ausstellungen</h1>
          <p className="intro">
            Seit 1984 regelmäßige Ausstellungen und Beteiligungen (Auswahl)
            <br />
            E = Einzelausstellung
          </p>
          <Timeline events={ausstellungen} />
        </Col>
      </Row>
    </StyledContainer>
  </Page>
);

Ausstellungen.propTypes = {
  show: PropTypes.bool,
};

export default Ausstellungen;
