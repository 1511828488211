import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

import Line from '../components/Line';
import Page from '../components/Page';
import text01 from '../content/text_2017-09-03';
import text02 from '../content/text_2013-04-22';
import textRedeVonStefanieBickel from '../content/textRedeVonStefanieBickel';

const StyledContainer = styled(Container)`
  font-size: 1.15rem;
  h1 {
    margin: 5rem 0 1.5rem;
  }
  .image-right {
    float: right;
    margin: 1.5rem 0 1.5rem 1.5rem;
    border: 4px solid white;
  }
  @media screen and (min-width: 992px) {
    font-size: 1.3rem;
  }
`;

const Texte = ({ show = false }) => (
  <Page show={show}>
    <StyledContainer>
      <Row>
        <Col>
          <h1>Texte</h1>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <div dangerouslySetInnerHTML={{ __html: text01 }} />
        </Col>
      </Row>
      <Row className="my-5">
        <Col>
          <Line />
          <img
            className="image-right mt-5"
            src="/img/mittelbayerische_menschen_als_energiefelder_1304.jpg"
            alt="Menschen als Energiefelder"
          />
          <div className="my-5" dangerouslySetInnerHTML={{ __html: text02 }} />
        </Col>
      </Row>
      <Row className="my-5">
        <Col>
          <Line />
          <div className="my-5" dangerouslySetInnerHTML={{ __html: textRedeVonStefanieBickel }} />
        </Col>
      </Row>
    </StyledContainer>
  </Page>
);

Texte.propTypes = {
  show: PropTypes.bool,
};

export default Texte;
