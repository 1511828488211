import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

import Page from '../components/Page';
import Timeline from '../components/Timeline';
import vita from '../content/vita';

const StyledContainer = styled(Container)`
  padding-bottom: 5rem;
  h1 {
    margin: 5rem 0 1.5rem 0;
    font-size: 3.75rem;
  }
  img {
    border: 10px solid white;
  }
`;

const Vita = ({ show = false }) => (
  <Page show={show}>
    <StyledContainer>
      <Row className="text-center">
        <Col>
          <h1>Vita</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={10}>
          <Timeline events={vita} />
        </Col>
      </Row>
    </StyledContainer>
  </Page>
);

Vita.propTypes = {
  show: PropTypes.bool,
};

export default Vita;
